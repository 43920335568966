

























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Signs, Months, BwaSummaryTable } from "@/consts";
import { getRouter } from "../../router";
import api from "../../api";
import store from "../../store";
import { getGermanNum, germanZahl, getFormattedNum } from "../../helper/utils";

@Component
export default class DrillTable extends Vue {
  @Prop() data!: any;
  @Prop() id!: string;
  @Prop() tableClass!: string;
  signs: any;
  months: any;
  germanZahl: any;
  getFormattedNum: any;
  treeNodes: any;
  dialogUrl: string = "";

  constructor() {
    super();
    this.signs = Signs;
    this.months = Months;
    this.germanZahl = germanZahl;
    this.getFormattedNum = getFormattedNum;
    this.treeNodes = this.cloneSource(this.data.body.treeNodes);
  }

  get bwaLinkStatus() {
    return store.getters.bwaLinkStatus;
  }

  @Watch("bwaLinkStatus")
  onBwaLinkStatusChange() {
    if (this.bwaLinkStatus) {
      this.treeNodes = this.cloneSource(this.data.body.treeNodes);
      Object.keys(this.treeNodes).forEach((key: any) => {
        if ((this.bwaLinkStatus as any)[key]) {
          let node = this.treeNodes[key];
          node[0] = ">";
          Vue.set(this.treeNodes, key, node);
        } else {
          let node = this.treeNodes[key];
          node[0] = "x";
          Vue.set(this.treeNodes, key, node);
        }
      });
      this.$forceUpdate();
    }
  }

  get storeData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  get monthTotal() {
    return "-";
  }

  cloneSource(source: any) {
    return JSON.parse(JSON.stringify(source));
  }

  getTotal(data: any) {
    let total = 0;
    data.forEach((d: any) => {
      if (this.id === "ums_table") {
        total += d[2];
      } else if (this.id === "kunden_table" || this.id === "ek_table") {
        total += d[1];
      }
    });
    return germanZahl(total);
  }

  viewUmsatzKunden(client: any) {
    // localStorage.setItem('cur_umsatzdetails_kunden', JSON.stringify([client]));
    this.setDialogUrl("/kundenanalyse");
  }

  isLinkExist(linkName: string) {
    return BwaSummaryTable[linkName];
  }

  bwaGoTo(bwaAccountCode: any, linkName: string) {
    var parameters = BwaSummaryTable[bwaAccountCode];
    if (!parameters) {
      return;
    }
    var action = parameters[0];
    var link = parameters[1];

    if (action == "redirect") {
      localStorage.setItem("from", "bwa_big_table");
      this.setDialogUrl("/" + link);
    } else if (action == "redirect_filter") {
      if (this.storeData.year && this.storeData.client) {
        api
          .getBwaKonto(
            this.storeData.client,
            this.storeData.year,
            bwaAccountCode
          )
          .then((res) => {
            localStorage.setItem(
              "buchungssaetze_filter_konto",
              JSON.stringify((res as any).data)
            );
            localStorage.setItem("from", "bwa_big_table");
            this.setDialogUrl("/" + link);
          });
      }
    } else if (action == "anchor") {
      const anchor: any = document.getElementById(link);
      anchor.scrollIntoView();
    }
  }

  bwaShowBuchungssaetze(bwaAccountCode: any) {
    localStorage.setItem(
      "buchungssaetze_filter_konto",
      JSON.stringify([bwaAccountCode])
    );
    localStorage.setItem("from", "bwa_big_table");
    this.setDialogUrl("/buchungssaetze");
  }

  fetchNode(accountCode: any, index: any) {
    // check node status
    let status = this.treeNodes[accountCode][0];
    if (status == ">") {
      let fetched = this.treeNodes[accountCode][4];
      if (fetched != true) {
        store.dispatch(
          "updateLoading",
          store.state.loading >= 0 ? store.state.loading + 1 : 1
        );
        api
          .getDrilldownBwa(
            this.storeData.client,
            this.storeData.year,
            accountCode
          )
          .then(async (res) => {
            let accountData = res.data;
            if (Object.keys(accountData).length > 0) {
              Vue.set(this.treeNodes, accountCode, ["v", 0, true, null, true]);
              this.$forceUpdate();

              // map received drilldown data to BWA data
              let codes: any = [];
              accountData.total.forEach((d: any) => {
                codes.push(d[0]);
              });

              let bwaChildrenStatus = (
                await api.getDrilldownBwaChildren(
                  this.storeData.client,
                  this.storeData.year,
                  codes
                )
              ).data;
              store.dispatch("updateLoading", store.state.loading - 1);

              accountData.total.forEach((d: any) => {
                this.data.body.current.legend.splice(index + 1, 0, [
                  d[0],
                  d[1],
                  false,
                ]);
                this.data.body.current.total[d[0]] = d[2];
                const sign =
                  bwaChildrenStatus && bwaChildrenStatus[d[0]] ? ">" : "x";
                Vue.set(this.treeNodes, d[0], [sign, 1, true, accountCode]);
              });

              this.months.forEach((monthName: any, index: any) => {
                let month = index + 1;

                // initialize with zero data
                accountData.total.forEach((d: any) => {
                  this.data.body.current[month][d[0]] = 0;
                });

                if (accountData[month]) {
                  accountData[month].forEach((d: any) => {
                    this.data.body.current[month][d[0]] = d[2];
                  });
                }
              });
            }
          })
          .catch((error) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            if (error.response.status == 404) {
              let nodeStatus = this.treeNodes[accountCode][0];
              let nodeIndentation = this.treeNodes[accountCode][1];
              let nodeParent = this.treeNodes[accountCode][3];
              Vue.set(this.treeNodes, accountCode, [
                "-",
                nodeIndentation,
                true,
                nodeParent,
              ]);
            }
          });
      } else {
        // just show children
        let keys = Object.keys(this.treeNodes);
        keys.forEach((key) => {
          let item = this.treeNodes[key];
          if (item[3] == accountCode) {
            Vue.set(this.treeNodes, key, [item[0], item[1], true, item[3]]);
          }
        });
        Vue.set(this.treeNodes, accountCode, ["v", 0, true, null, true]);
      }
    } else if (status == "v") {
      // hide all children
      let keys = Object.keys(this.treeNodes);
      keys.forEach((key) => {
        let item = this.treeNodes[key];
        if (item[3] == accountCode) {
          Vue.set(this.treeNodes, key, [item[0], item[1], false, item[3]]);
        }
      });
      Vue.set(this.treeNodes, accountCode, [">", 0, true, null, true]);
    }
  }

  setDialogUrl(path: string) {
    const baseUrl = window.location.origin;
    this.dialogUrl = baseUrl + path;
  }
}
