
















import Widget from "@/components/common/Widget.vue";
import KPI from "@/components/common/KPI.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    KPI,
  },
})
export default class Umsatzrentabilitaet extends Vue {
  public static id = "Umsatzrentabilitaet";
  public static dprops = { width: 4, height: 4, minHeight: 2, minWidth: 2 };

  get _id() {
    return Umsatzrentabilitaet.id;
  }
  get _dprops() {
    return Umsatzrentabilitaet.dprops;
  }
}
