


















import Widget from "@/components/common/Widget.vue";
import { Months, BwaTreeNodes } from "../../consts";
import DrillTable from "@/components/table/DrillTable.vue";

import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";
import store from "@/store";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    DrillTable,
  },
})
export default class BetriebswirtschaftlicheAuswertung extends Vue {
  public static id = "BetriebswirtschaftlicheAuswertung";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return BetriebswirtschaftlicheAuswertung.id;
  }
  get _dprops() {
    return BetriebswirtschaftlicheAuswertung.dprops;
  }
  get auswertung() {
    let head: any = [{ title: "Bereich" }, { title: "Gesamt" }];
    Months.forEach((m) => {
      head.push({ title: m });
    });
    if (
      store.getters.currentCalcs &&
      store.getters.currentCalcs.BWA &&
      store.getters.currentCalcs.BWA.legend
    ) {
      store.getters.currentCalcs.BWA.legend.sort((a: any, b: any) => {
        if (a[0] > b[0]) {
          return 1;
        } else if (a[0] < b[0]) {
          return -1;
        }
        return 0;
      });
    }
    return {
      head: head,
      body: {
        current: this.cloneSource(
          store.getters.currentCalcs && store.getters.currentCalcs.BWA
        ),
        treeNodes: this.cloneSource(BwaTreeNodes),
      },
    };
  }
  /**
   * Get clone copy of an object
   * This is to enssure no change in original object if there is any modification on a copied object
   */
  cloneSource(source: any) {
    return source && JSON.parse(JSON.stringify(source));
  }
}
