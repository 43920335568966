














































































import TopBar from "@/components/common/TopBar.vue";

import Vue from "vue";
import store from "../../store";

import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { bwaRenderChartUmsatzTop10 } from "../../helper/charts";
import api from "../../api";
import {
  callCalc,
  callLieferantentop5,
  callMaterialdetail,
  callYearsums,
  callPrevCalc,
  callPrevYearsums,
  callPlan,
} from "../../helper/utils";
import lodash from "lodash";

import ErfolgsrechnungTable from "@/components/DashboardItems/ErfolgsrechnungTable.vue";
import Top10UmsatzerloeseBereiche from "@/components/DashboardItems/Top10UmsatzerloeseBereiche.vue";
import Top10UmsatzerloeseKunden from "@/components/DashboardItems/Top10UmsatzerloeseKunden.vue";
import Umsatzrentabilitaet from "@/components/DashboardItems/Umsatzrentabilitaet.vue";
import Umsatzwachstum from "@/components/DashboardItems/Umsatzwachstum.vue";
import Umsatzerloese from "@/components/DashboardItems/Umsatzerloese.vue";
import Top5WareneinkaufGesamt from "@/components/DashboardItems/Top5WareneinkaufGesamt.vue";
import WareneinkaufGesamtPieChart from "@/components/DashboardItems/WareneinkaufGesamtPieChart.vue";
import WareneinkaufEtw from "@/components/DashboardItems/WareneinkaufEtw.vue";
import Top5Kostenbereiche from "@/components/DashboardItems/Top5Kostenbereiche.vue";
import Top5KostenbereichePieChart from "@/components/DashboardItems/Top5KostenbereichePieChart.vue";
import Kostenbereiche from "@/components/DashboardItems/Kostenbereiche.vue";
import BetriebsergebnisVergleich from "@/components/DashboardItems/BetriebsergebnisVergleich.vue";
import BetriebsergebnisGesamt from "@/components/DashboardItems/BetriebsergebnisGesamt.vue";
import BetriebsergebnisGesamtJahre from "@/components/DashboardItems/BetriebsergebnisGesamtJahre.vue";
import EBITVorlaeufigesErgebnis from "@/components/DashboardItems/EBITVorlaeufigesErgebnis.vue";
import EBITGesamt from "@/components/DashboardItems/EBITGesamt.vue";
import EBITGesamtJahre from "@/components/DashboardItems/EBITGesamtJahre.vue";
import EBITDAVergleich from "@/components/DashboardItems/EBITDAVergleich.vue";
import EBITDAGesamt from "@/components/DashboardItems/EBITDAGesamt.vue";
import EBITDAGesamtJahre from "@/components/DashboardItems/EBITDAGesamtJahre.vue";
import BetriebswirtschaftlicheAuswertung from "@/components/DashboardItems/BetriebswirtschaftlicheAuswertung.vue";

import UmsatzHorizontalBar from "@/components/charts/UmsatzHorizontalBar.vue";

@Component({
  components: {
    TopBar,

    UmsatzHorizontalBar,
    ErfolgsrechnungTable,
    Top10UmsatzerloeseBereiche,
    Top10UmsatzerloeseKunden,
    Umsatzrentabilitaet,
    Umsatzwachstum,
    Umsatzerloese,
    Top5WareneinkaufGesamt,
    WareneinkaufGesamtPieChart,
    WareneinkaufEtw,
    Top5Kostenbereiche,
    Top5KostenbereichePieChart,
    Kostenbereiche,
    BetriebsergebnisVergleich,
    BetriebsergebnisGesamt,
    BetriebsergebnisGesamtJahre,
    EBITVorlaeufigesErgebnis,
    EBITGesamt,
    EBITGesamtJahre,
    EBITDAVergleich,
    EBITDAGesamt,
    EBITDAGesamtJahre,
    BetriebswirtschaftlicheAuswertung,
  },
})
export default class Erfolgsrechnung extends Vue {
  initPage = false;

  get prevCalcs() {
    return store.getters.previousCalcs;
  }

  get currentCalcs() {
    return store.getters.currentCalcs;
  }

  get chartData() {
    return {
      data: store.getters.currentCalcs,
      prevData: store.getters.previousCalcs,
    };
  }

  get resize() {
    return {
      resize: store.getters.resize,
    };
  }

  get calculationPlan() {
    return {
      data: store.getters.currentCalcs,
    };
  }

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
      comparison: store.getters.planSelected,
    };
  }

  loadFromPlan() {
    let client = JSON.parse(String(localStorage.getItem("client")));
    let year = JSON.parse(String(localStorage.getItem("year")));

    api
      .getPlanData(client, year)
      .then((res: any) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        let prevData = null;
        if (this.prevCalcs) {
          prevData = lodash.cloneDeep(this.prevCalcs);
        } else {
          prevData = { BWA: lodash.cloneDeep(this.currentCalcs["BWA"]) };
        }
        if (res && res.data) {
          if (prevData) {
            prevData["BWA"] = res.data.plan_month;
          }
        } else {
          if (prevData) {
            const l = prevData["BWA"]["legend"];
            for (var i = 0; i < l.length; i++) {
              prevData["BWA"]["total"][l[i][0]] = 0;
              for (var month = 1; month < 13; month++) {
                prevData["BWA"][month][l[i][0]] = 0;
              }
            }
          }
        }
        store.dispatch("updatePreviousCalcs", prevData);
      })
      .catch((err) => {
        store.dispatch("updatePreviousCalcs", null);
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  pushStateHistory(
    stb: any,
    mandant: any,
    year: any,
    range: any,
    comparison: any
  ) {
    const selectedStb = this.biAgency != null ? this.biAgency : stb;
    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year) +
      "/" +
      encodeURIComponent(range) +
      "/" +
      encodeURIComponent(comparison);

    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.topBarData.year && this.topBarData.client) {
      if (!store.getters.isCalcCalled) {
        callCalc();
      }
      if (!store.getters.isPrevCalcCalled && !store.getters.planSelected) {
        callPrevCalc();
      }
      if (store.getters.planSelected) {
        callPlan(store.getters.selectedYear[0]);
      }
      if (!store.getters.isLieferantentop5Called) {
        callLieferantentop5({});
      }
      if (!store.getters.isMaterialdetailCalled) {
        callMaterialdetail({});
      }
      if (!store.getters.isYearsumsCalled) {
        callYearsums();
      }
      if (!store.getters.isPrevYearsumsCalled) {
        callPrevYearsums();
      }

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }

  @Watch("topBarNavigation")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let mandant: string =
      this.topBarNavigation.client && this.topBarNavigation.client[1];
    let stb: string =
      this.topBarNavigation.client && this.topBarNavigation.client[0];
    let year: string =
      this.topBarNavigation.year && this.topBarNavigation.year[0];
    if (mandant && year) {
      this.pushStateHistory(
        stb,
        mandant,
        year,
        store.getters.rangeStep,
        store.getters.planSelected ? "planjahr" : "vorjahr"
      );
    }
  }

  @Watch("chartData")
  drawCharts() {
    bwaRenderChartUmsatzTop10();
    this.getDrilldownBwaChildren();
  }

  @Watch("resize")
  onResize() {
    bwaRenderChartUmsatzTop10();
  }

  @Watch("calculationPlan")
  loadPlan() {
    let initPlan = JSON.parse(String(localStorage.getItem("initPlan")));
    if (initPlan) {
      this.loadFromPlan();
      localStorage.setItem("initPlan", JSON.stringify(false));
    }
  }

  getDrilldownBwaChildren() {
    if (this.chartData.data && this.chartData.data.BWA) {
      let ids: any = [];
      this.chartData.data.BWA.legend.forEach((l: any) => {
        ids.push(l[0]);
      });
      if (this.topBarData.client && this.topBarData.year) {
        store.dispatch(
          "updateLoading",
          store.state.loading >= 0 ? store.state.loading + 1 : 1
        );
        api
          .getDrilldownBwaChildren(
            this.topBarData.client,
            this.topBarData.year,
            ids
          )
          .then((res) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            store.dispatch("updateBwaLinkStatus", res.data);
          })
          .catch((err) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            store.dispatch("updateBwaLinkStatus", null);
          });
      }
    }
  }

  mounted() {
    this.initPage = true;
    this.onTopBarDataChange();
    this.drawCharts();
    if (localStorage.getItem("from") == "bwa_big_table") {
      (document.getElementById("bwa_table_auswertung") as any).scrollIntoView();
      localStorage.removeItem("from");
    } else if (localStorage.getItem("from") == "bwa_table") {
      (document.getElementById("bwa_table") as any).scrollIntoView();
      localStorage.removeItem("from");
    }
  }
}
