












import Vue from 'vue'
import Component from 'vue-class-component'
import store from '../../store'
import { Prop } from 'vue-property-decorator'
import { germanZahl } from '../../helper/utils'

@Component
export default class KPI extends Vue {
    @Prop() id!: string

    get kpiData() {
        return {
            year: store.getters.selectedYear,
            years: store.getters.years,
            current: store.getters.currentCalcs && store.getters.currentCalcs.BWA && store.getters.currentCalcs.BWA.total,
            prev: store.getters.previousCalcs && store.getters.previousCalcs.BWA && store.getters.previousCalcs.BWA.total,
            curYearSums: store.getters.currentYearSums,
            prevYearSums: store.getters.previousYearSums,
        }
    }

    get value() {
        if (this.id === 'betriebsergebnis' && this.kpiData.current && this.kpiData.current.KPI) {
            return germanZahl(this.kpiData.current.KPI.URT.toFixed(1)).slice(0, -1)
        } else if (this.id === 'umsatzwachstum' && this.kpiData.curYearSums) {
            return germanZahl(this.kpiData.curYearSums.UMSATZWACHSTUM.toFixed(1)).slice(0, -1)
        } else {
            return '0,0'
        }
    }

    get diffValue() {
        if (this.id === 'betriebsergebnis' && this.kpiData.current && this.kpiData.current.KPI && this.kpiData.prev) {
            return this.kpiData.current.KPI.URT - (this.kpiData.prev.KPI ? this.kpiData.prev.KPI.URT : 0)
        } else if (this.id === 'umsatzwachstum' && this.kpiData.curYearSums && this.kpiData.prevYearSums) {
            return this.kpiData.curYearSums.UMSATZWACHSTUM - this.kpiData.prevYearSums.UMSATZWACHSTUM
        } else {
            return 0
        }
    }

    get formattedDiffVal() {
        return germanZahl(this.diffValue.toFixed(1)).slice(0, -1)
    }
}
