




















import Widget from "@/components/common/Widget.vue";
import VerticalLineAndBarGroupChart from "@/components/charts_js/VerticalLineAndBarGroupChart.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";
import store from "@/store";
import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    VerticalLineAndBarGroupChart,
  },
})
export default class EBITVorlaeufigesErgebnis extends Vue {
  public static id = "EBITVorlaeufigesErgebnis";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return EBITVorlaeufigesErgebnis.id;
  }
  get _dprops() {
    return EBITVorlaeufigesErgebnis.dprops;
  }
  get planSelected() {
    return store.getters.planSelected;
  }
}
