

















import Widget from "@/components/common/Widget.vue";

import StaticTable from "@/components/table/StaticTable.vue";

import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";
import store from "../../store";

import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,

    StaticTable,
  },
})
export default class ErfolgsrechnungTable extends Vue {
  public static id = "ErfolgsrechnungTable";
  public static dprops = { width: 5, height: 4, minWidth: 4, minHeight: 4 };
  get _id() {
    return ErfolgsrechnungTable.id;
  }
  get _dprops() {
    return ErfolgsrechnungTable.dprops;
  }
  get ubersichtstabelle() {
    let ubersichtstabelleHead: any = [];
    store.watch(
      (getter) => {
        if (getter.previousCalcs) {
          ubersichtstabelleHead = [
            { title: "Bereich" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
            {
              title: "Vergleich",
              description: getter.planSelected
                ? store.getters.selectedYear && store.getters.selectedYear[0]
                : store.getters.selectedYear &&
                  store.getters.selectedYear[0] - 1,
              class: "text-center",
            },
            {
              title: "Abweichung",
              description: "Ist/Referenz",
              class: "text-center",
              colspan: 2,
            },
          ];
        } else {
          ubersichtstabelleHead = [
            { title: "Bereich" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
          ];
        }
      },
      (getters) => {}
    );
    return {
      head: ubersichtstabelleHead,
      body: {
        prev: store.getters.previousCalcs && store.getters.previousCalcs.BWA,
        current: store.getters.currentCalcs && store.getters.currentCalcs.BWA,
      },
    };
  }
}
