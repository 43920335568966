














import Widget from "@/components/common/Widget.vue";
import SmallPie from "@/components/charts/SmallPie.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    SmallPie,
  },
})
export default class WareneinkaufGesamtPieChart extends Vue {
  public static id = "WareneinkaufGesamtPieChart";
  public static dprops = {
    width: 4,
    height: 4,
    minWidth: 3,
    minHeight: 3,
    maxWidth: 4,
    maxHeight: 4,
  };
  get _id() {
    return WareneinkaufGesamtPieChart.id;
  }
  get _dprops() {
    return WareneinkaufGesamtPieChart.dprops;
  }
}
