




































































import Widget from "@/components/common/Widget.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
  },
})
export default class Top10UmsatzerloeseBereiche extends Vue {
  public static id = "Top10UmsatzerloeseBereiche";
  public static dprops = { width: 4, height: 4, minWidth: 3, minHeight: 3 };
  get _id() {
    return Top10UmsatzerloeseBereiche.id;
  }
  get _dprops() {
    return Top10UmsatzerloeseBereiche.dprops;
  }
}
