


















































































import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import { rightRoundedRect } from "@/helper/charts";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import { getGermanNum } from "@/helper/utils";
import store from "@/store";
import { getRouter } from "../../router";
declare const d3: any;
declare const $: any;

@Component({ components: { NoDataHtml } })
export default class UmsatzHorizontalBar extends Vue {
  @Prop() id!: string;
  @Prop() dataId!: string;
  @Prop() dataIndex!: number;
  @Prop() page!: string;

  chart: any = [];

  get chartData() {
    return {
      cur: store.getters.currentCalcs,
      prev: store.getters.previousCalcs,
      resize: store.getters.resize,
    };
  }

  mounted() {
    this.onDataChange();
  }

  @Watch("chartData")
  onDataChange() {
    let d0: any;
    let d1: any;
    if (this.dataIndex) {
      d0 =
        (this.chartData.cur &&
          this.chartData.cur[this.dataId] &&
          this.chartData.cur[this.dataId][this.dataIndex]) ||
        [];
      d1 =
        (this.chartData.prev &&
          this.chartData.prev[this.dataId] &&
          this.chartData.prev[this.dataId][this.dataIndex]) ||
        [];
    } else {
      d0 = (this.chartData.cur && this.chartData.cur[this.dataId]) || [];
      d1 = (this.chartData.prev && this.chartData.prev[this.dataId]) || [];
    }
    if (this.page === "umsatz" || this.page === "erfolg") {
      this.drawUmsatzHorizontalBar(d0, d1, this.id);
    } else if (this.page === "lieferanten") {
      this.ekRenderMultiHorizontalBarChart(d0, this.id);
    }
  }

  umsatzShowBooking(accountCode: any) {
    localStorage.setItem(
      "buchungssaetze_filter_konto",
      JSON.stringify([accountCode])
    );
    localStorage.setItem("from", this.id);
    getRouter().push("/buchungssaetze");
  }

  viewUmsatzKunden(item: any) {
    let client = item["hor_code"];
    localStorage.setItem("cur_umsatzdetails_kunden", JSON.stringify([client]));
    getRouter().push("/kundenanalyse");
  }

  drawUmsatzHorizontalBar(d0r: any, d1r: any, id: any) {
    var i = 1;
    var maxVal = 0;
    this.chart = [];
    var el: any = document.getElementById(id + "initial_width");
    for (var it in d0r) {
      if (!d0r || !d0r[it]) {
        return;
      }

      var current = d0r[it][1];
      var previous = 0;
      var width: number = el.offsetWidth - 12 - 10;
      var height: number = 32;

      if (d1r && typeof d1r[it] !== "undefined") {
        previous = d1r[it][1];
      }

      if (d0r && d0r[it] && d0r[it][1] > maxVal) {
        maxVal = d0r[it][1];
      }

      if (d1r && d1r[it] && d1r[it][1] > maxVal) {
        maxVal = d1r[it][1];
      }
      var previous_bar_path: any;
      var current_bar_path: any;
      if (d0r[it]) {
        current_bar_path = d0r[it][1]
          ? rightRoundedRect(0, 0, (d0r[it][1] * width) / maxVal, 14, 4)
          : "";
      }

      if (d1r[it]) {
        previous_bar_path = d1r[it][1]
          ? rightRoundedRect(0, 18, (d1r[it][1] * width) / maxVal, 14, 4)
          : "";
      }

      this.chart.push({
        hor_num: i < 10 ? "0" + i : i,
        hor_code: d0r[it][0],
        hor_label: d0r[it][2],
        svg_id: id + "-" + i,
        previous_bar_path: previous_bar_path,
        current_bar_path: current_bar_path,
        hor_current_value:
          current / 1000000 > 1
            ? getGermanNum((current / 1000000).toFixed(1)) + "M"
            : getGermanNum((current / 1000).toFixed(1)) + "k",
        hor_previous_value:
          previous / 1000000 > 1
            ? getGermanNum((previous / 1000000).toFixed(1)) + "M"
            : getGermanNum((previous / 1000).toFixed(1)) + "k",
      });
      i++;
    }
  }

  ekRenderMultiHorizontalBarChart(d0: any, id: any) {
    if (!d3.selectAll("#" + id).node()) {
      return;
    }
    if (!d0 || d0.length === 0) {
      return;
    }

    var maxVal = 1;
    this.chart = [];
    var el: any = document.getElementById(id + "initial_width");

    for (var i = 0; i < d0.length; i++) {
      if (d0[i][1] > maxVal) {
        maxVal = d0[i][1];
      }
      var width: number = el.offsetWidth;
      var height: number = 32;
      this.chart.push({
        account_code: d0[i][0],
        hor_label: d0[i][2],
        hor_num: (0 + (i + 1).toString()).slice(-2),
        svg_wrapper_id: id + i,
        hor_value:
          d0[i][1] / 1000000 >= 1
            ? getGermanNum((d0[i][1] / 1000000).toFixed(1)) + "M"
            : getGermanNum((d0[i][1] / 1000).toFixed(1)) + "K",
        path_d: rightRoundedRect(
          0,
          height / 4,
          (d0[i][1] * width) / maxVal,
          height / 2,
          4
        ),
      });
    }
  }
}
