


















import Widget from "@/components/common/Widget.vue";
import UmsatzHorizontalBar from "@/components/charts/UmsatzHorizontalBar.vue";

import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    UmsatzHorizontalBar,
  },
})
export default class Top10UmsatzerloeseKunden extends Vue {
  public static id = "Top10UmsatzerloeseKunden";
  public static dprops = { width: 4, height: 4, minWidth: 3, minHeight: 3 };
  get _id() {
    return Top10UmsatzerloeseKunden.id;
  }
  get _dprops() {
    return Top10UmsatzerloeseKunden.dprops;
  }
}
